import { useState, useEffect } from 'react';
import './WheelOfFortune.css';
import CustomAlert from './CustomAlert';

export default function WheelOfFortune() {
    const [spinTickets, setSpinTickets] = useState(0);
    const [alertMessage, setAlertMessage] = useState(null); // For prize display
    const [isSpinning, setIsSpinning] = useState(false);
    const [wheelRotation, setWheelRotation] = useState(0);
    const username = localStorage.getItem('username');

    useEffect(() => {
        if (!username) {
            console.error('User not found.');
            return;
        }

        // Fetch user data when the component mounts
        async function fetchSpinInfo() {
            try {
                const response = await fetch(`https://softcoin-axlm.onrender.com/api/users/${username}/spin-info`);
                const data = await response.json();
                setSpinTickets(data.spinTickets);
            } catch (error) {
                console.error('Error fetching tickets:', error);
            }
        }
        fetchSpinInfo();
    }, [username]);

    const closeAlert = async () => {
        setAlertMessage(null); // Reset alert message to hide the alert
        setIsSpinning(false); // Enable spin button when alert is closed

        // Fetch updated spin tickets when the alert is closed
        try {
            const response = await fetch(`https://softcoin-axlm.onrender.com/api/users/${username}/spin-info`);
            const data = await response.json();
            setSpinTickets(data.spinTickets); // Update the spin tickets after the alert is closed
        } catch (error) {
            console.error('Error fetching updated tickets:', error);
        }
    };

    const spinWheel = async () => {
        if (spinTickets <= 0) {
            setAlertMessage('You have no spin tickets left. Try again later.');
            return;
        }

        // Deduct a spin ticket
        setSpinTickets(spinTickets - 1);

        setIsSpinning(true);

        // Reset wheel rotation to start fresh
        setWheelRotation(0);

        // Logic for wheel spinning and selecting the prize
        const probabilityProgress = {
            ranges: [
                { min: 162, max: 197, probability: 0.7 },
                { min: 90, max: 125, probability: 0.17485 },
                { min: 54, max: 90, probability: 0.05 },
                { min: 18, max: 54, probability: 0.05 },
                { min: 306, max: 342, probability: 0.025 },
                { min: 235, max: 270, probability: 0.00015 },
                { min: 0, max: 18, probability: 0 },
                { min: 270, max: 306, probability: 0 }
            ]
        };

        const random = Math.random();
        let cumulativeProbability = 0;
        let chosenRange;

        for (const range of probabilityProgress.ranges) {
            cumulativeProbability += range.probability;
            if (random < cumulativeProbability) {
                chosenRange = range;
                break;
            }
        }

        const finalDegree = Math.floor(Math.random() * (chosenRange.max - chosenRange.min + 1)) + chosenRange.min;
        const totalRotations = 360 * 6; // At least 6 full rotations

        const targetRotation = totalRotations + finalDegree; // Total rotation amount with overshoot
        const spinDuration = 3000; // Total spin duration in ms
        const startRotation = 0; // Start from zero for a fresh spin
        const startTime = performance.now(); // Record the start time

        const animateSpin = (currentTime) => {
            const elapsedTime = currentTime - startTime;
            const progress = Math.min(elapsedTime / spinDuration, 1); // Progress from 0 to 1

            // Ease-out effect for slowing down toward the end
            const easedProgress = easeOutCubic(progress);

            // Calculate the current rotation based on eased progress
            const currentRotation = startRotation + (targetRotation - startRotation) * easedProgress;
            setWheelRotation(currentRotation);

            if (progress < 1) {
                requestAnimationFrame(animateSpin); // Continue animation
            } else {
                determinePrize(finalDegree); // Finalize the prize

                // Update the server about the ticket deduction
                fetch('https://softcoin-axlm.onrender.com/api/updateSpinTickets', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ username, spinTickets: -1 })
                }).catch(error => console.error('Error updating spin tickets:', error));
            }
        };

        requestAnimationFrame(animateSpin); // Start the animation
    };

    // Ease-out cubic function for smoother deceleration
    function easeOutCubic(t) {
        return 1 - Math.pow(1 - t, 3);
    }

    const determinePrize = (degree) => {
        let prize, rewardType;

        if (degree >= 0 && degree < 18) {
            prize = 0.10;
            rewardType = 'usd';
        } else if (degree >= 18 && degree < 54) {
            prize = 10000;
            rewardType = 'sft';
        } else if (degree >= 54 && degree < 90) {
            prize = 2;
            rewardType = 'tickets';
        } else if (degree >= 90 && degree < 126) {
            prize = 5000;
            rewardType = 'sft';
        } else if (degree >= 126 && degree < 162) {
            prize = 10;
            rewardType = 'usd';
        } else if (degree >= 162 && degree < 198) {
            prize = 2000;
            rewardType = 'sft';
        } else if (degree >= 198 && degree < 234) {
            prize = 2;
            rewardType = 'usd';
        } else if (degree >= 234 && degree < 270) {
            prize = 5;
            rewardType = 'tickets';
        } else if (degree >= 270 && degree < 306) {
            prize = 0.50;
            rewardType = 'usd';
        } else if (degree >= 306 && degree < 342) {
            prize = 50000;
            rewardType = 'sft';
        } else {
            prize = 0.10;
            rewardType = 'usd';
        }

        // Use CustomAlert to display the prize
        setAlertMessage(`You won ${prize} ${rewardType.toUpperCase()}!`);

        // Move the fetch call here so it can access prize and rewardType
        fetch('https://softcoin-axlm.onrender.com/api/updatePrize', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ username, prize, rewardType })
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                console.log('Prize successfully added to user account');
            } else {
                console.error('Error updating prize:', data.error);
            }
        }).catch(error => {
            console.error('Error updating prize:', error);
        });
    };

    return (
        <div className="wheel app">
            <a href="/more"><i className="fas fa-chevron-left" style={{ color: 'white', position: 'fixed', left: '20px', top: '40px', zIndex: '500' }}></i></a>
            <h2>Wheel Of Fortune</h2>
            <p>What Does Your Luck Have In Store For You Today?<br />Spin the wheel to find out!</p>

            <div className="ticketbox">
                <img src="game/ticket.png" alt="Ticket Icon" />
                <span id="spin-tickets">{spinTickets}</span>
            </div>

            <div className="wheel-container">
                <img
                    id="wheel"
                    src="/wheel.png"
                    alt="Spin the Wheel"
                    style={{ transform: `rotate(${wheelRotation}deg)` }}
                />
                <div id="pointer"></div>
            </div>

            <button id="spin-button" onClick={spinWheel} disabled={isSpinning}>
                {isSpinning ? 'Spinning...' : 'SPIN THE WHEEL'}
            </button>

            <canvas id="confetti-canvas"></canvas>

            {/* Custom Alert */}
            {alertMessage && <CustomAlert message={alertMessage} onClose={closeAlert} />}
        </div>
    );
}
