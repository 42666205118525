import { useNavigate } from 'react-router-dom';

export default function BottomNav({ activeItem, setActiveItem }) {
  const navigate = useNavigate();

  return (
    <nav className="bottom-nav">
      <ul>
        <li>
          <button
            onClick={() => {
              setActiveItem('home');
              navigate('/Home');
            }}
            className={`nav-btn ${activeItem === 'home' ? 'active' : ''}`}
          >
          {/*}  <i className="fas fa-home"></i> */}
          <img src="menu/home-neon.png" />
            <span>Home</span>
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              setActiveItem('tasks');
              navigate('/tasks');
            }}
            className={`nav-btn ${activeItem === 'tasks' ? 'active' : ''}`}
          >
           {/* <i className="fas fa-tasks"></i> */}
           <img src="menu/tasks-neon.png" />
            <span>Tasks</span>
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              setActiveItem('friends');
              navigate('/friends');
            }}
            className={`nav-btn ${activeItem === 'friends' ? 'active' : ''}`}
          >
           {/*} <i className="fas fa-user-friends"></i> */}
           <img src="menu/friends-neon.png" />
            <span>Friends</span>
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              setActiveItem('softie');
              navigate('/softie');
            }}
            className={`nav-btn ${activeItem === 'softie' ? 'active' : ''}`}
          >
          {/*}  <i className="fas fa-robot"></i> */}
          <img src="menu/softie-neon.png" />
            <span>Softie</span>
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              setActiveItem('more');
              navigate('/more');
            }}
            className={`nav-btn ${activeItem === 'more' ? 'active' : ''}`}
          >
           {/*} <i className="fas fa-ellipsis-h"></i> */}
           <img src="menu/more-neon.png" />
            <span>More</span>
          </button>
        </li>
      </ul>
    </nav>
  );
}
