// CustomAlert.js
import './CustomAlert.css';

function CustomAlert({ message, onClose }) {
  return (
    <div className="custom-alerts">
      <div className="alert-content">
        <p className="message">{message}</p>
        <button className="alert-button" onClick={onClose}>Ok</button>
      </div>
    </div>
  );
}

export default CustomAlert;
