import { useState, useEffect, useMemo, useCallback } from 'react';
import { useNavigate, Routes, Route } from 'react-router-dom';
import './App.css';
import logo from './softcoin-logo.png';
import cash from './cash.png';
import coins from './coins.png';
import ticket from './game/ticket.png';
import BottomNav from './BottomNav';

const slides = [
  { image: '/adverts/softiebanner.png', link: '/softie' },
  { image: '/adverts/trybebanner.png', link: '/trybe' },
  { image: '/adverts/telegram.png', link: 'https://t.me/softcoinupdates' },
  { image: '/adverts/join.png', link: 'https://twitter.com/softcoinproject' },
];

export default function Home() {
  const [activeItem, setActiveItem] = useState('home');
  const [username, setUsername] = useState(null);
  const [coinBalance, setCoinBalance] = useState(0);
  const [earningBalance, setEarningBalance] = useState(0);
  const [currentLevel, setCurrentLevel] = useState(1);
  const [miningSessionCount, setMiningSessionCount] = useState(0);
  const [spinTickets, setSpinTickets] = useState(0);
  const [statusMessage, setStatusMessage] = useState("");
  const [timer, setTimer] = useState("00:00:00");
  const [timerInterval, setTimerInterval] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [greeting, setGreeting] = useState('Hello');
  const [commitmentBalance, setCommitmentBalance] = useState(null);
  const [dailyProfit, setDailyProfit] = useState(0);
  const [softieLevel, setSoftieLevel] = useState('');
  const [error, setError] = useState('');
  const [dynamicCoinCount, setDynamicCoinCount] = useState(0);
  const [walletAddress, setWalletAddress] = useState('');  // Add walletAddress state
  const [showMiningAlert, setShowMiningAlert] = useState(false); // For controlling modal visibility
  const navigate = useNavigate();

  const rewardIntervals = useMemo(() => [2 * 60 * 60 * 1000, 3 * 60 * 60 * 1000, 4 * 60 * 60 * 1000, 5 * 60 * 60 * 1000, 6 * 60 * 60 * 1000], []);
  
  const rewardIntervalsInHours = useMemo(() => [2, 3, 4, 5, 6], []);
  
  const rewards = useMemo(() => [5000, 10000, 20000, 40000, 80000], []);
  
  const [unreadCount, setUnreadCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  
  const handleClick = () => {
    if (!walletAddress) {
      navigate('/connect-wallet');
    }
  };
  
  useEffect(() => {
    const storedUsername = localStorage.getItem('username');
    if (storedUsername) {
      setUsername(storedUsername);
      // Fetch wallet address if username is available
      fetchWalletAddress(storedUsername);
    } else {
      navigate('/login');
    }
  }, [navigate]);
  
const fetchWalletAddress = async (username) => {
  try {
    // Corrected the URL by removing the colon
    const response = await fetch(`https://softcoin-axlm.onrender.com/api/walletAddress/${username}`);
    const data = await response.json();

    if (data.walletAddress) {
      // Corrected the variable name to `data.walletAddress`
      setWalletAddress(data.walletAddress);  // Set the wallet address
    } else {
      setWalletAddress('');  // If wallet address is not found, clear the wallet address
    }
  } catch (error) {
    console.error('Error fetching wallet address:', error);
  }
};
  
  useEffect(() => {
    const fetchGreeting = () => {
      const currentHour = new Date().getHours();
      if (currentHour < 12) {
        setGreeting("Good morning");
      } else if (currentHour < 18) {
        setGreeting("Good afternoon");
      } else {
        setGreeting("Good evening");
      }
    };

    fetchGreeting();
  }, []);
  
  useEffect(() => {
    async function fetchNotifications() {
      try {
        const response = await fetch(`https://softcoin-axlm.onrender.com/api/notifications/${username}`);
        let notificationsData = await response.json();

        // Sort notifications by date (most recent first)
        notificationsData.sort((a, b) => new Date(b.date) - new Date(a.date));

        let unread = 0;
        notificationsData.forEach(notification => {
          if (!notification.read) unread++;
        });

        setNotifications(notificationsData);
        setUnreadCount(unread);
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    }

    fetchNotifications();
  }, [username]);
  
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 10000);
  
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (username) {
      const fetchEarningBalance = async () => {
        try {
          const response = await fetch(`https://softcoin-axlm.onrender.com/api/users/${username}/earningBalance`);
          if (!response.ok) {
            throw new Error('Failed to fetch earning balance');
          }
          const data = await response.json();
          setEarningBalance(data.earningBalance.toFixed(2));
        } catch (error) {
          console.error('Error fetching earning balance:', error);
        }
      };

      fetchEarningBalance();
    }
  }, [username]);
  
  useEffect(() => {
        fetch(`https://softcoin-axlm.onrender.com/api/users/${username}/commitmentBalance`)
            .then(response => response.json())
            .then(data => {
                if (data.error) {
                    setError('Error fetching commitment balance');
                } else {
                    const balance = data.commitmentBalance;
                    setCommitmentBalance(balance);

                    let profit = 0;
                    let level = '';

                    if (balance >= 5 && balance <= 30) {
                        profit = balance * 0.015;
                        level = 'Rising Softie';
                    } else if (balance >= 31 && balance <= 100) {
                        profit = balance * 0.02;
                        level = 'Junior Softie';
                    } else if (balance >= 101 && balance <= 500) {
                        profit = balance * 0.025;
                        level = 'Pro Softie';
                    } else if (balance >= 501 && balance <= 2000) {
                        profit = balance * 0.03;
                        level = 'Expert Softie';
                    } else if (balance >= 2001 && balance <= 5000) {
                        profit = balance * 0.035;
                        level = 'Master Softie';
                    } else if (balance >= 5001) {
                        profit = balance * 0.04;
                        level = 'Legend Softie';
                    }

                    setDailyProfit(profit.toFixed(2));
                    setSoftieLevel(level);
                }
            })
            .catch(() => {
                setError('Error fetching commitment balance');
            });
    }, [username]);

  const startMining = async () => {
    try {
      const response = await fetch('https://softcoin-axlm.onrender.com/api/startMining', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username })
      });
      const data = await response.json();
      if (data.miningStartTime) {
        startTimer(new Date(data.miningStartTime).getTime(), data.level);
        setCoinBalance(data.coinBalance);
        setCurrentLevel(data.level);
        setMiningSessionCount(data.miningSessionCount);
        setSpinTickets(data.spinTickets);
        setShowMiningAlert(true); // Show alert modal when mining starts
      } else {
        console.error(data.message || "Error starting mining");
      }
    } catch (error) {
      console.error("Failed to start mining", error);
    }
  };

  const handleSpinNow = () => {
    setShowMiningAlert(false);
    navigate('/wheel-of-fortune'); // Navigate to the spinning wheel
  };

  const handleLater = () => {
    setShowMiningAlert(false); // Close the alert
  };

  const startTimer = useCallback((startTime, level) => {
  const endTime = startTime + rewardIntervals[level - 1];
  const totalReward = rewards[level - 1];

  const updateTimer = () => {
    const remainingTime = endTime - Date.now();
    if (remainingTime <= 0) {
      clearInterval(timerInterval);
      setTimer("00:00:00");
      setStatusMessage("Mining complete!");
      setCoinBalance(prevBalance => prevBalance + totalReward); // Update the static balance after mining is done
      setDynamicCoinCount(0); // Reset the dynamic count
      setMiningSessionCount(prevCount => prevCount + 1);
    } else {
      const hours = Math.floor((remainingTime / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((remainingTime / (1000 * 60)) % 60);
      const seconds = Math.floor((remainingTime / 1000) % 60);
      setTimer(`${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`);

      // Calculate the newly mined coins so far
      const elapsedTime = Date.now() - startTime;
      const minedCoins = totalReward * (elapsedTime / rewardIntervals[level - 1]);

      // Update only the dynamic coin count
      setDynamicCoinCount(minedCoins);
    }
  };

  updateTimer();
  const interval = setInterval(updateTimer, 1000); // Update every second
  setTimerInterval(interval);
}, [timerInterval, rewards, rewardIntervals]);

  const updateMiningStatus = useCallback(async (username) => {
    try {
      const response = await fetch('https://softcoin-axlm.onrender.com/api/miningStatus', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username })
      });
      const data = await response.json();
      if (data.miningStartTime) {
        startTimer(new Date(data.miningStartTime).getTime(), data.level);
        setCoinBalance(data.coinBalance);
        setCurrentLevel(data.level);
        setMiningSessionCount(data.miningSessionCount);
        setSpinTickets(data.spinTickets);
        setStatusMessage("Mining in progress...");
      } else if (data.miningComplete) {
        setCoinBalance(data.coinBalance);
        setCurrentLevel(data.level);
        setMiningSessionCount(data.miningSessionCount);
        setSpinTickets(data.spinTickets);
        setStatusMessage("Mining complete!");
      } else {
        setStatusMessage("Mining not started");
      }
    } catch (error) {
      setStatusMessage("Failed to retrieve mining status");
    }
  }, [startTimer]);

  useEffect(() => {
    const storedUsername = localStorage.getItem('username');
    if (storedUsername) {
      setUsername(storedUsername);
      updateMiningStatus(storedUsername);
    } else {
      navigate('/login');
    }
  }, [navigate, updateMiningStatus]);
  
  return (
    <div className="app">
      <header className="top-bar">
        <div className="greeting">
       {/*  <img src={logo} alt="Logo" className="logo" /> */}
          <p style={{ textAlign: 'left', marginBottom: '', fontSize: '8px', color: '#48e4fa' }}>{greeting},<br/><span style={{ textAlign: 'left', fontSize: '18px', fontWeight: 'bold', color: 'white' }}>{username}!</span></p>
        </div>
        <div className="icons">
          <a href="/upgrade" className="icon-container">
            <i className="fas fa-bolt"></i>
          </a>
          <div className="icon-container">
            <i style={{ color: '#48e4fa' }} className="fas fa-user-circle"></i>
          </div>
          <a href="/notification" className="icon-container bell">
            <i className="fas fa-bell"></i>
             {unreadCount > 0 && (
              <span className="icon-badge">{unreadCount}</span>
            )}
          </a>

        </div>
      </header>
      
      <div className="app-body">
        <div className="slideshow">
  {slides.map((slide, index) => {
    let className = 'slide';
    if (index === currentSlide) {
      className += ' active';
    } else if (index === ((currentSlide - 1 + slides.length) % slides.length)) {
      className += ' prev';
    } else if (index === ((currentSlide + 1) % slides.length)) {
      className += ' next';
    }

    return (
      <a key={index} href={slide.link} target="_blank" rel="noopener noreferrer" id="slide">
        <img
          src={slide.image}
          alt={`Slide ${index + 1}`}
          className={className}
        />
      </a>
    );
  })}
</div>
      <div className="vector">
        <div className="balance-box">
        <div className="balance-item">
          <img src="/menu/home-neon.png" alt="Coin Logo" className="balance-logo coin-logo" />
          <span className="balance-text coin">{coinBalance.toFixed(0)}<br/><span style={{ color: '#48e4fa', fontSize: '12px'}}>SFT</span></span>
        </div>
        <div className="balance-item">
          <img src="/menu/softie-neon.png" alt="Earning Logo" className="balance-logo" />
          <span className="balance-text">{earningBalance !== null ? earningBalance : "Loading..."}<br/><span style={{ color: '#48e4fa', fontSize: '12px'}}>USD</span></span>
        </div>
      </div>
      <div className="wallet">
     <button 
      className="connect" 
      onClick={handleClick} 
      disabled={!!walletAddress}  // Disable if walletAddress is not empty
    >
      {walletAddress ? '🛡 Wallet Connected️!' : 'Connect Wallet 🚀'}  
    </button>
        
        <a href="/withdraw" className="cashout"><span>Cash Out</span>
        <i className="fas fa-chevron-right" style={{ color: '#041f4c', marginLeft: '50px' }}></i>
        </a>
      </div>
      </div>

      {/* Coin Balance */}
      <div className="coin-balance">
    <strong>
      <p id="coin-balance" style={{ marginLeft: '-10px' }} >+ {dynamicCoinCount.toFixed(0)}</p>
    </strong>
  </div> 
  <div className="status-message"><p id="status-message">{statusMessage}</p></div>
  
<button
  id="mine-btn"
  onClick={startMining}
  disabled={statusMessage === "Mining in progress..."}
  className={statusMessage === "Mining complete!" ? "breathing" : statusMessage === "Mining in progress..." ? "circulate" : ""}
>
  {statusMessage === "Mining in progress..." ? timer : ""}
</button>

<div className="levels">
  <p>Daily Income: <span>${dailyProfit}</span></p>
  <p>Mining Level: <span>{currentLevel}</span></p>
  <p>Sessions Completed: <span>{miningSessionCount}</span></p>
  <div className="ticket-box" ><img className="ticket" src={ticket} alt="ticket"/><p>{spinTickets}</p></div>
    
</div>

<p style={{ display: 'none'}}>{error}</p>
</div>
      {/* Conditional Alert Modal */}
      {showMiningAlert && (
        <div className="mining-alert">
          <div className="alert-content">
            <h2>Mining started successfully!</h2>
            <p>Session ends in {rewardIntervalsInHours[currentLevel - 1]} hours.</p>
            <p>Reward: {rewards[currentLevel - 1]} SFT</p>
            <p>You now have {spinTickets} Spin Tickets. Go spin now for more rewards!</p>
            <div className="alert-buttons">
              <button onClick={handleSpinNow}>Spin</button>
              <button style={{ background: 'gray'}} onClick={handleLater}>Later</button>
            </div>
          </div>
        </div>
      )}
      <BottomNav activeItem={activeItem} setActiveItem={setActiveItem} />
    </div>
  );
}
