// Softie.js
import BottomNav from './BottomNav';
import { useState, useEffect } from 'react';
import './Softie.css'; 

export default function Softie() {
  const [activeItem, setActiveItem] = useState('softie');
  const [username, setUsername] = useState(localStorage.getItem('username'));
  const [earningBalance, setEarningBalance] = useState(0);
  const [currentLevel, setCurrentLevel] = useState(1);
  const [commitmentBalance, setCommitmentBalance] = useState(null);
  const [dailyProfit, setDailyProfit] = useState(0);
  const [softieLevel, setSoftieLevel] = useState('');
  const [error, setError] = useState('');
  const [leaders, setLeaders] = useState([]);
  const [userRank, setUserRank] = useState('--');

  // Fetch leaderboard data
  useEffect(() => {
    async function loadLeaderboard() {
      try {
        const response = await fetch(`https://softcoin-axlm.onrender.com/api/leaderboard/${username}`);
        const data = await response.json();

        setLeaders(data.leaders);
        setUserRank(data.userRank || '--');
      } catch (error) {
        console.error('Error loading leaderboard:', error);
      }
    }

    if (username) {
      loadLeaderboard();
    }
  }, [username]);

  // Fetch earning balance and commitment balance
  useEffect(() => {
    const fetchEarningBalance = async () => {
      try {
        const response = await fetch(`https://softcoin-axlm.onrender.com/api/users/${username}/earningBalance`);
        if (!response.ok) {
          throw new Error('Failed to fetch earning balance');
        }
        const data = await response.json();
        setEarningBalance(data.earningBalance.toFixed(2));
      } catch (error) {
        console.error('Error fetching earning balance:', error);
      }
    };

    const fetchCommitmentBalance = async () => {
      try {
        const response = await fetch(`https://softcoin-axlm.onrender.com/api/users/${username}/commitmentBalance`);
        const data = await response.json();
        if (data.error) {
          setError('Error fetching commitment balance');
        } else {
          const balance = data.commitmentBalance;
          setCommitmentBalance(balance);

          let profit = 0;
          let level = '';

          if (balance >= 5 && balance <= 30) {
            profit = balance * 0.015;
            level = 'Rising Softie';
          } else if (balance >= 31 && balance <= 100) {
            profit = balance * 0.02;
            level = 'Junior Softie';
          } else if (balance >= 101 && balance <= 500) {
            profit = balance * 0.025;
            level = 'Pro Softie';
          } else if (balance >= 501 && balance <= 2000) {
            profit = balance * 0.03;
            level = 'Expert Softie';
          } else if (balance >= 2001 && balance <= 5000) {
            profit = balance * 0.035;
            level = 'Master Softie';
          } else if (balance >= 5001) {
            profit = balance * 0.04;
            level = 'Legend Softie';
          }

          setDailyProfit(profit.toFixed(2));
          setSoftieLevel(level);
        }
      } catch (error) {
        console.error('Error fetching commitment balance:', error);
      }
    };

    if (username) {
      fetchEarningBalance();
      fetchCommitmentBalance();
    }
  }, [username]);

  return (
    <div className="main-page app">
      <div className="income">
        <div className="income-text over">Your Daily Income:</div>
        <div className="income-figure"><div className="decor two">${dailyProfit}</div><div className="decor">{earningBalance} USD</div></div>
        <div className="income-text under">Your Balance</div>
      </div>
      <div className="softie-bodys">
      <div className="commit">
        <div><p>Commitment: <span>{commitmentBalance} USD </span></p></div>
        <div className="softie-level">{softieLevel}</div>
      </div>
      <div className="mymenu">
        <div className="tiles"><a href="/deposit" className="tile"><img src="softies/deposit.png"/><p>Deposit</p></a><a href="/withdraw" className="tile"><img src="softies/cash-out.png"/><p>Cash Out</p></a></div>
        <div className="tiles"><a href="https://softcoin.world/softie" className="tile" target="_blank" rel="noopener noreferrer"><img src="softies/whats.png" alt="What's Softie?" /> <p>What's Softie?</p></a><a href="/softie-levels" className="tile"><img src="softies/levels.png"/><p>Levels</p></a></div>
      </div>
      <div className="explain">Become a Softcoin Shareholder and start earning daily income in USD.<br/><br/>Get started by Depositing at least $5 into your Commitment Account.<br/><br/>As a Shareholder, you get a daily return of up to 4% of your committed fund. And this return will be paid everyday.<br/><br/>You can earn up to $500 daily, depending on the amount you have in your portfolio. Your commitment will determine your softie level, and your softie level will subsequently dictate your daily income percentage.<br/><br/>Commitments will be unlocked and fully refunded to Shareholders after Token Generation Event (TGE).<br/><br/>The minimum withdrawal is $10, and you can withdraw your earnings at anytime.<br/><br/>Click <a href="https://softcoin.world/softie" target="_blank" rel="noopener noreferrer">here</a> to learn more.</div>
      <div className="leaderboard">
        <h2>Softie Leaderboard</h2>
        <div className="user-rank">
            <p>Your Rank: <span>{userRank}</span></p>
          </div>
        <h3>Top 10</h3>
        <section className="leaderboard-section">
          <ul className="leaderboard-list">
            {leaders.map((leader, index) => (
              <li key={leader.username} className="leaderboard-item">
                <div className="details">
                  <span className="username">{leader.username}</span>
                  <span className="commitment">Commitment: ${leader.commitmentBalance}</span>
                  <span className="cash-out">Cashed Out: ${leader.totalWithdrawal}</span>
                </div>
                <div className="rank-indicator">
                  {index === 0 && <img src="game/first.png" alt="1st rank" className="rank-icon" />}
                  {index === 1 && <img src="game/second.png" alt="2nd rank" className="rank-icon" />}
                  {index === 2 && <img src="game/third.png" alt="3rd rank" className="rank-icon" />}
                  {index > 2 && <span>{index + 1}</span>}
                </div>
              </li>
            ))}
          </ul>
          
        </section>
      </div>
      <div className="explain down">Deposit more USD into your commitment account to increase your daily income, go higher on the leaderboard, and unlock additional mining benefits.</div>
      </div>
      <BottomNav activeItem={activeItem} setActiveItem={setActiveItem} />
    </div>
  );
}
