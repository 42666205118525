import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './Notification.css'; // Add your CSS for styling

export default function Notification() {
  const [username, setUsername] = useState(localStorage.getItem('username'));
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [expandedNotification, setExpandedNotification] = useState(null);

  useEffect(() => {
    async function fetchNotifications() {
      try {
        const response = await fetch(`https://softcoin-axlm.onrender.com/api/notifications/${username}`);
        let notificationsData = await response.json();

        // Sort notifications by date (most recent first)
        notificationsData.sort((a, b) => new Date(b.date) - new Date(a.date));

        let unread = 0;
        notificationsData.forEach(notification => {
          if (!notification.read) unread++;
        });

        setNotifications(notificationsData);
        setUnreadCount(unread);
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    }

    fetchNotifications();
  }, [username]);

  const handleExpandNotification = (notificationId) => {
    setExpandedNotification(prev => (prev === notificationId ? null : notificationId));
    const updatedNotifications = notifications.map(notification => 
      notification.id === notificationId ? { ...notification, read: true } : notification
    );
    setNotifications(updatedNotifications);
    
    // Mark notification as read
    markNotificationAsRead(notificationId);
  };

  const markNotificationAsRead = async (id) => {
    try {
      await fetch('https://softcoin-axlm.onrender.com/api/notifications/markRead', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ notificationId: id })
      });
      setUnreadCount(prev => prev - 1);
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  return (
    <div className="notification-page">
      <a href="/home"><i className="fas fa-chevron-left" style={{ color: 'white', position: 'fixed', left: '20px', top: '40px', zIndex: '500'}}></i></a>
      <h2>Notifications</h2>
      <div className="notification-list">
        {notifications.map(notification => (
          <div 
            key={notification.id} 
            className={`notification-item ${notification.read ? 'read' : 'unread'}`} 
            onClick={() => handleExpandNotification(notification.id)}
          >
            <div className="notification-title">{notification.title}</div>
            <div className="notification-timestamp">
              {new Date(notification.date).toLocaleString()}
            </div>
            {expandedNotification === notification.id && (
              <div 
                className="notification-message" 
                dangerouslySetInnerHTML={{ __html: notification.message }}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
