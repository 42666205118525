import { useEffect } from 'react';
import { useNavigate, Routes, Route } from 'react-router-dom';
import './App.css';
import Login from './Login';
import Register from './Register';
import ResetPassword from './ResetPassword';
import ResendVerification from './ResendVerification';
import Tasks from './Tasks';
import Friends from './Friends';
import Softie from './Softie';
import More from './More';
import BottomNav from './BottomNav';
import Home from './Home';
import { SoundWaveLoader } from './SoundWaveLoader';
import Upgrade from './Upgrade';
import Notification from './Notification';
import ConnectWallet from './ConnectWallet';
import Withdraw from './Withdraw';
import Deposit from './Deposit';
import SoftieLevels from './SoftieLevels';
import History from './History';
import CryptoMarket from './CryptoMarket';
import WheelOfFortune from './WheelOfFortune';

function App() {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      const username = localStorage.getItem('username');
      if (username) {
        navigate('/home');
      } else {
        navigate('/login');
      }
    }, 3000); // 5 seconds delay

    // Cleanup timer on component unmount
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="plain-page">
      <div className="logo-container">
        <img style={{ width: '250px'}} src="transparent-logo2/9.png" alt="Softcoin Logo" className="logo" />
      </div>
      <div className="loader" >
      <SoundWaveLoader /> 
      </div>
    </div>
  );
}

export default function AppContainer() {
  const navigate = useNavigate();

  useEffect(() => {
    const hasReloaded = sessionStorage.getItem('hasReloaded');

    if (!hasReloaded && window.performance) {
      if (performance.navigation.type === 1) { // Page reload detected
        sessionStorage.setItem('hasReloaded', 'true'); // Mark reload occurred
        navigate('/'); // Redirect to root page
      }
    }
  }, [navigate]);

  return (
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/resend-verification" element={<ResendVerification />} />
      <Route path="/tasks" element={<Tasks />} />
      <Route path="/friends" element={<Friends />} />
      <Route path="/softie" element={<Softie />} />
      <Route path="/more" element={<More />} />
      <Route path="/home" element={<Home />} />
      <Route path="/upgrade" element={<Upgrade />} />
      <Route path="/notification" element={<Notification />} />
      <Route path="/connect-wallet" element={<ConnectWallet />} />
      <Route path="/withdraw" element={<Withdraw />} />
      <Route path="/deposit" element={<Deposit />} />
      <Route path="/softie-levels" element={<SoftieLevels />} />
      <Route path="/history" element={<History />} />
      <Route path="/crypto-market" element={<CryptoMarket />} />
      <Route path="/wheel-of-fortune" element={<WheelOfFortune />} />
    </Routes>
  );
}
