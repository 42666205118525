import React, { useState, useEffect } from 'react';
import './Notification.css'; // Use your existing CSS or create a new file for styling

export default function History() {
  const [transactions, setTransactions] = useState([]);
  const username = localStorage.getItem('username'); // Retrieve username from local storage

  useEffect(() => {
    if (!username) {
      console.error('Username not found in local storage');
      return;
    }

    // Fetch withdrawals and transactions and merge them
    const fetchTransactions = async () => {
      try {
        const withdrawalsResponse = await fetch(`https://softcoin-axlm.onrender.com/api/users/${username}/withdrawals`);
        const withdrawalsData = await withdrawalsResponse.json();

        const transactionsResponse = await fetch(`https://softcoin-axlm.onrender.com/api/users/${username}/transactions`);
        const transactionsData = await transactionsResponse.json();

        // Format the withdrawals and transactions for display
        const formattedWithdrawals = withdrawalsData.withdrawals.map(withdrawal => ({
          type: 'Withdrawal',
          amount: -withdrawal.amount,
          date: Date.parse(withdrawal.createdAt) ? new Date(withdrawal.createdAt) : 'Invalid Date',
          status: withdrawal.status,
          currency: withdrawal.currency,
          isWithdrawal: true,
         }));

       const formattedTransactions = transactionsData.map(transaction => ({
         type: 'Deposit',
         amount: transaction.amount,
         date: Date.parse(transaction.createdAt) ? new Date(transaction.createdAt) : 'Invalid Date',
         status: transaction.status,
         currency: transaction.cryptoType,
        isWithdrawal: false,
        }));


        // Combine and sort by date (descending)
        const combinedTransactions = [...formattedWithdrawals, ...formattedTransactions].sort(
          (a, b) => b.date - a.date
        );

        setTransactions(combinedTransactions);
      } catch (error) {
        console.error('Error fetching transaction history:', error);
      }
    };

    fetchTransactions();
  }, [username]);

  return (
    <div className="transaction-page">
      <a href="/softie">
        <i className="fas fa-chevron-left" style={{ color: 'white', position: 'fixed', left: '20px', top: '40px', zIndex: '500' }}></i>
      </a>
      <h2>Transaction History</h2>
      <div className="transaction-list">
        {transactions.map((transaction, index) => (
          <TransactionItem key={index} transaction={transaction} />
        ))}
      </div>
    </div>
  );
}

function TransactionItem({ transaction }) {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="transaction-item" onClick={() => setExpanded(!expanded)}>
      <div className="transaction-summary">
        <span className="transaction-title">{transaction.type}</span>
        <span 
          className="transaction-amount" 
          style={{ color: transaction.isWithdrawal ? '#fd9e9e' : '#78ed78' }}
        >
          {transaction.isWithdrawal ? '-' : '+'}${Math.abs(transaction.amount)}
        </span>
      </div>
      {expanded && (
        <div className="transaction-details">
          <p>Status: {transaction.status}</p>
          <p>Date: {transaction.date.toLocaleString()}</p>
          <p>Currency: {transaction.currency}</p>
        </div>
      )}
    </div>
  );
}
