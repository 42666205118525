import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';  // Assuming you are using react-router for navigation
import './Reset.css';
import './Register.css';

function ResetPassword() {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  const navigate = useNavigate();  // useNavigate hook for redirecting

  const handleResetPassword = async () => {
    if (!email) {
      setAlertMessage('Please enter an email.');
      setAlertType('danger');
      return;
    }

    setLoading(true);
    try {
      const response = await fetch('https://softcoin-axlm.onrender.com/api/auth/forgot-password', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email })
      });

      const data = await response.json();
      setLoading(false);
      setAlertMessage(data.message);
      setAlertType(response.ok ? 'success' : 'danger');

      if (response.ok) {
        setTimeout(() => {
          navigate('/login');  // Redirect to login page after 5 seconds
        }, 5000);
      }
    } catch (error) {
      setLoading(false);
      console.error('Error:', error);
      setAlertMessage('Error sending reset link. Please try again.');
      setAlertType('danger');
    }
  };

  return (
    <div className="app">
    <div className="reset-password-container form-body">
      <div className="login-container">
        <div className="logo-container">
        <img src="transparent-logo2/10.png" alt="Softcoin Logo" className="logo" />
      </div>
      <div className="form">
        <h2>Reset Password</h2>
        <div id="alert-box" className={`custom-alert ${alertType ? `alert-${alertType}` : ''}`} style={{ display: alertMessage ? 'block' : 'none' }}>
          {alertMessage}
        </div>
        <input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button className="submit-button" style={{ boxShadow: 'box-shadow: #68016600;' }} onClick={handleResetPassword} disabled={loading}>
          {loading ? 'Sending...' : 'Send Reset Link'}
        </button>
        {alertMessage && (
          <div className={`custom-alert alert-${alertType}`}>
            {alertMessage}
          </div>
        )}
      </div>
              <div className="verify">
          <p>Enter your email address, click on the "Send Reset Link" button, and a link to change your password will be sent to your mailbox.</p>
        </div>
        </div>
    </div>
    </div>
  );
}

export default ResetPassword;
