import React, { useEffect, useState } from 'react';
import BottomNav from './BottomNav';
import './Login.css'; 
import './Tasks.css';
import { SoundWaveLoader } from './SoundWaveLoader';
import CustomAlert from './CustomAlert'; // Import CustomAlert

export default function Tasks() {
  const [activeItem, setActiveItem] = useState('tasks');
  const [loading, setLoading] = useState(true);
  const [tasks, setTasks] = useState([]);
  const [username, setUsername] = useState(localStorage.getItem('username') || '');
  const [alertMessage, setAlertMessage] = useState(null); // Manage alert state

  const closeAlert = () => {
    setAlertMessage(null); // Reset alertMessage to hide the alert
  };

  const taskDetails = {
        "followw-btn": { name: "Follow Softcoin on X", target: 1, reward: 10000, action: "Follow", logo: "tasks/follow.png" },
        "join-btn": { name: "Join Softcoin Telegram Channel", target: 1, reward: 10000, action: "Join", logo: "tasks/join.png" },
        "refer2-btn": { name: "Invite 2 friends", target: 2, reward: 30000, logo: "tasks/refer2.png" },
        "refer5-btn": { name: "Invite 5 friends", target: 5, reward: 50000, logo: "tasks/refer5.png" },
        "refer10-btn": { name: "Invite 10 friends", target: 10, reward: 100000, logo: "tasks/refer10.png" },
        "mine2-btn": { name: "Complete 2 mining sessions", target: 2, reward: 10000, logo: "tasks/mine2.png" },
        "mine20-btn": { name: "Complete 20 mining sessions", target: 20, reward: 100000, logo: "tasks/mine20.png" },
        "mine100-btn": { name: "Complete 100 mining sessions", target: 100, reward: 1000000, logo: "tasks/mine100.png" },
        "post-btn": { name: "Make a post on X", target: 1, reward: 10000, action: "Post", logo: "tasks/post.png" },
        "earnbitmoon": { name: "Join EarnBitMoon", target: 1, reward: 10000, action: "Join", logo: "tasks/earnbitmoon.png" },
        "cats": { name: "Join the CatsGang", target: 1, reward: 10000, action: "Join", logo: "tasks/catsgang.png" },
        "sauce": { name: "Get some Sauce", target: 1, reward: 10000, action: "Go", logo: "tasks/sauces.png" },
        "satoshi": { name: "Join Satoshi", target: 1, reward: 10000, action: "Join", logo: "tasks/satoshi.png" },
        "truecoin": { name: "Start Mining Truecoin", target: 1, reward: 10000, action: "Start", logo: "tasks/truecoin.png" },
        "athene": { name: "Start Mining Athene", target: 1, reward: 10000, action: "Start", logo: "tasks/athene.png" },
        "simple": { name: "Join Simple Tap", target: 1, reward: 10000, action: "Join", logo: "tasks/simpletap.png" },
        "cex": { name: "Start Mining CEX", target: 1, reward: 10000, action: "Start", logo: "tasks/cex.png" },
        "match-quest": { name: "Join MatchQuest", target: 1, reward: 10000, action: "Join", logo: "tasks/matchquest.png" },
        "blum": { name: "Start Farming BLUM", target: 1, reward: 10000, action: "Start", logo: "tasks/blum.png" },
        "seed-btn": { name: "Start farming Seeds", target: 1, reward: 10000, action: "Start", logo: "tasks/seed.png" },
        "farm-btn": { name: "Farm $TOMATO", target: 1, reward: 10000, action: "Farm", logo: "tasks/tomato.png" },
        "sunwave": { name: "Join SUNWAVES", target: 1, reward: 10000, action: "Go", logo: "tasks/sunwave.png" },
        "call": { name: "Join CALL", target: 1, reward: 10000, action: "Join", logo: "tasks/call.png" },
        "lost-dog": { name: "Join the Lost Dogs", target: 1, reward: 10000, action: "Join", logo: "tasks/lostdogs.png" },
        "major": { name: "Become A Major", target: 1, reward: 10000, action: "Go", logo: "tasks/major.png" },
        "money-dog": { name: "Start Farming Money-Dog", target: 1, reward: 10000, action: "Start", logo: "tasks/moneydog.png" },
        "mozoai": { name: "Join MozoAI", target: 1, reward: 10000, action: "Join", logo: "tasks/mozoai.png" },
        "nomiz": { name: "Start Mining Nomis", target: 1, reward: 10000, action: "Start", logo: "tasks/nomis.png" },
        "time-farm": { name: "Start Farming Time", target: 1, reward: 10000, action: "Start", logo: "tasks/time.png" },
        "vertus": { name: "Join Vertus", target: 1, reward: 10000, action: "Join", logo: "tasks/vertus.png" },
        "hot": { name: "Start Farming HOT", target: 1, reward: 10000, action: "Start", logo: "tasks/hot.png" },
        "wonton": { name: "Earn some $WONTON", target: 1, reward: 10000, action: "Earn", logo: "tasks/wonton.png" },
        "sidefan": { name: "Join SideFan", target: 1, reward: 10000, action: "Join", logo: "tasks/sidefan.png" },
        "agent": { name: "Join Agent301", target: 1, reward: 10000, action: "Join", logo: "tasks/agent301.png" },
         "pocket": { name: "Join PocketFi", target: 1, reward: 10000, action: "Join", logo: "tasks/pocketfi.png" },
         "freebitcoin": { name: "Start Earning Free Bitcoin", target: 1, reward: 10000, action: "Start", logo: "tasks/freebitcoin.png" },
         "coinpayu": { name: "Start Earning On Coinpayu", target: 1, reward: 10000, action: "Start", logo: "tasks/coinpayu.png" },
  };

  useEffect(() => {
    if (!username) {
      window.location.href = '/login';
    } else {
      loadInitialTasks();
    }
  }, [username]);

  const loadInitialTasks = async () => {
  const taskKeys = Object.keys(taskDetails);
  const initialTaskPromises = taskKeys.slice(0, 10).map(async (taskId) => {
    const task = taskDetails[taskId];
    const [progressCount, claimed] = await Promise.all([
      fetchProgress(taskId),
      fetchTaskStatus(taskId),
    ]);

    const isClaimable = progressCount >= task.target && !claimed;
    let buttonText;

    if (claimed) {
      buttonText = "✔";
    } else if (isClaimable) {
      buttonText = "Claim";
    } else {
      buttonText = task.target === 1 ? task.action : `${progressCount}/${task.target}`;
    }

    return {
      id: taskId,
      ...task,
      progress: progressCount,
      claimed,
      buttonText,
    };
  });

  const initialTasks = await Promise.all(initialTaskPromises);
  setTasks(initialTasks);
  setLoading(false);

  // Trigger the loading of the remaining tasks
  loadRemainingTasks(taskKeys.slice(10));
};

const loadRemainingTasks = async (remainingTaskKeys) => {
  const remainingTaskPromises = remainingTaskKeys.map(async (taskId) => {
    const task = taskDetails[taskId];
    const [progressCount, claimed] = await Promise.all([
      fetchProgress(taskId),
      fetchTaskStatus(taskId),
    ]);

    const isClaimable = progressCount >= task.target && !claimed;
    let buttonText;

    if (claimed) {
      buttonText = "✔";
    } else if (isClaimable) {
      buttonText = "Claim";
    } else {
      buttonText = task.target === 1 ? task.action : `${progressCount}/${task.target}`;
    }

    return {
      id: taskId,
      ...task,
      progress: progressCount,
      claimed,
      buttonText,
    };
  });

  const remainingTasks = await Promise.all(remainingTaskPromises);
  setTasks((prevTasks) => [...prevTasks, ...remainingTasks]);
};


  const fetchProgress = async (taskId) => {
    if (taskId.startsWith('refer')) {
      return fetchReferralCount();
    } else if (taskId.startsWith('mine')) {
      return fetchMiningSessionCount();
    } else {
      return 0;
    }
  };

  const fetchTaskStatus = async (taskId) => {
    try {
      const response = await fetch(`https://softcoin-axlm.onrender.com/api/taskStatus/${username}/${taskId}`);
      const data = await response.json();
      return data.success && data.claimed;
    } catch (error) {
      console.error('Error fetching task status:', error);
      return false;
    }
  };

  const fetchReferralCount = async () => {
    try {
      const response = await fetch(`https://softcoin-axlm.onrender.com/api/referrals/${username}`);
      const data = await response.json();
      return data.referrals.length || 0;
    } catch (error) {
      console.error('Error fetching referral count:', error);
      return 0;
    }
  };

  const fetchMiningSessionCount = async () => {
    try {
      const response = await fetch(`https://softcoin-axlm.onrender.com/api/miningSessionCount/${username}`);
      const data = await response.json();
      return data.miningSessionCount || 0;
    } catch (error) {
      console.error('Error fetching mining session count:', error);
      return 0;
    }
  };

  const claimTask = async (taskId, reward) => {
    try {
      const response = await fetch(`https://softcoin-axlm.onrender.com/api/claimTask`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, taskId, reward })
      });

      const data = await response.json();

      if (response.ok && data.success) {
        showCustomAlert('Reward claimed successfully!');
        moveTaskToCompleted(taskId);
        return true;
      } else {
        showCustomAlert('Error claiming reward.');
        return false;
      }
    } catch (error) {
      console.error('Error claiming task:', error);
      showCustomAlert('Error claiming reward.');
      return false;
    }
  };


  const moveTaskToCompleted = (taskId) => {
    setTasks((prevTasks) =>
      prevTasks.map(task =>
        task.id === taskId ? { ...task, claimed: true, buttonText: '✔' } : task
      )
    );
  };

  const showCustomAlert = (message) => {
    setAlertMessage(message); // Set the custom alert message to trigger the alert
};

  const handleButtonClick = (task) => {
    if (task.claimed) return;

    if (task.buttonText === "Claim") {
      claimTask(task.id, task.reward);
    } else {
      const url = {
                'followw-btn': 'https://twitter.com/softcoinproject',
                'agent': 'https://t.me/Agent301Bot/app?startapp=onetime1170015722',
                'freebitcoin': 'https://freebitco.in/?r=41656831',
                'sidefan': 'https://t.me/sidekick_fans_bot?start=1170015722',
                'wonton': 'https://t.me/WontonOrgBot/gameapp?startapp=referralCode=ZQU4FUL6',
                'coinpayu': 'https://www.coinpayu.com/?r=Davidnelson',
                'pocket': 'https://t.me/pocketfi_bot/Mining?startapp=1731371837',
                'sauce': 'https://sauces.world/@davidnelson',
                'cats': 'https://t.me/catsgang_bot/join?startapp=rLwerPH0TY6fTsuSKrikC',
                'satoshi': 'https://invite.satoshiapp.xyz/7j5qq',
                'truecoin': 'https://t.me/true_coin_bot?start=1170015722',
                'earnbitmoon': 'https://earnbitmoon.club/?ref=125258',
                'match-quest': 'https://t.me/MatchQuestBot/start?startapp=b0e03025b2ca2160315e2ebcacf4da52',
                'blum': 'https://t.me/BlumCryptoBot/app?startapp=ref_f1LDj4pzs5',
                'cex': 'https://t.me/cexio_tap_bot?start=1716270247236481',
                'simple': 'https://t.me/Simple_Tap_Bot/app?startapp=1716360435372',
                'athene': 'https://t.me/athene_official_bot/app?startapp=7575c96cae4c',
                'join-btn': 'https://t.me/softcoinupdates',
                'farm-btn': 'https://t.me/Tomarket_ai_bot/app?startapp=00001S56',
                'major': 'https://t.me/major/start?startapp=1170015722',
                'lost-dog': 'https://t.me/lost_dogs_bot/lodoapp?startapp=ref-u_1170015722__s_578244',
                'seed-btn': 'https://t.me/seed_coin_bot/app?startapp=1170015722',
                'sunwave': 'https://sunwavestoken.com/@davidnelson',
                'call': 'https://callfluent.io/@1170015722',
                'money-dog': 'https://t.me/money_dogs_bot/money_dogs?startapp=ou7n7ZHh',
                'mozoai': 'https://t.me/MozoAI_bot?start=1RE6BtMt',
                'nomiz': 'https://t.me/NomisAppBot/app?startapp=ref_Lw3iG-VUpB',
                'time-farm': 'https://t.me/TimeFarmCryptoBot?start=52dNTEOsZ344hdH9',
                'vertus': 'https://t.me/vertus_app_bot/app?startapp=1170015722',
                'hot': 'https://t.me/herewalletbot/app?startapp=1496880-village-27134',
                'post-btn': `https://twitter.com/intent/tweet?text=%F0%9F%93%88%20Ready%20to%20maximize%20your%20crypto%20potential%3F%0A%0ASoftcoin%20offers%20unparalleled%20opportunities%20with%20our%20referral%20and%20mining%20rewards.%0A%0AJoin%20us%20at%20softcoin.world%20and%20start%20earning%20today!%0A%0A%23CryptoLife%20%23CryptoRewards%20%23SoftCoin`
      }[task.id];

      if (url) {
        window.open(url, '_blank');
        setTasks(prevTasks =>
          prevTasks.map(t =>
            t.id === task.id ? { ...t, buttonText: "Claim" } : t
          )
        );
      }
    }
  };

  return (
    <body>
            
    <div className="tasks-container app">

      <h2>Tasks</h2>
      <p style={{ display: 'none' }}>Hello, {setUsername}</p>
      <p>Complete these tasks and claim more rewards.</p>

      {loading ? (
        <SoundWaveLoader /> 
      ) : (
<div id="tasks-container">
  {tasks.map((task) => (
    <div key={task.id} className={`task ${task.claimed ? 'claimed' : ''}`}>
      <div id="main-div">
        <img src={task.logo} alt={`${task.name} logo`} className="task-logo" />
        <div id="task-item-div">
          <span>{task.name}</span>
          <p className="reward">REWARD: <span>{task.reward}</span> SFT</p>
        </div>
        <div id="action-div">
          <button
            id={task.id}
            onClick={() => handleButtonClick(task)}
            className={`task-button ${task.claimed ? 'disabled' : task.buttonText === "Claim" ? 'green' : 'faint-green'}`}
            disabled={task.claimed}
          >
            {task.claimed ? "✔" : task.buttonText === "Claim" ? "Claim" : task.buttonText}
          </button>
        </div>
      </div>
    </div>
  ))}
</div>
      )}
            
{/* Custom Alert */}
    {alertMessage && <CustomAlert message={alertMessage} onClose={closeAlert} />}
  </div>
      <BottomNav activeItem={activeItem} setActiveItem={setActiveItem} />
    </body>
  );
}
