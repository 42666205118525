
import BottomNav from './BottomNav';
import { useState } from 'react';
import './Login.css'; 

export default function More() {
  const [activeItem, setActiveItem] = useState('more');

  return (
    <div className="more app">
      <h1>More Page</h1>
      <div>
        <a href="/crypto-market">Crypto Market</a><br/>
        <a href="/wheel-of-fortune">Wheel Of Fortune</a>
      </div>
      <BottomNav activeItem={activeItem} setActiveItem={setActiveItem} />
    </div>
  );
}
