import { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import the useNavigate hook
import './ConnectWallet.css'; 
import CustomAlert from './CustomAlert';

export default function ConnectWallet() {
  const [walletAddress, setWalletAddress] = useState('');
  const [loading, setLoading] = useState(false);  // State to track loading status
  const [alertMessage, setAlertMessage] = useState(null); // Manage alert state
  const navigate = useNavigate();  // Initialize the useNavigate hook

  const closeAlert = () => {
    setAlertMessage(null); // Reset alertMessage to hide the alert
  };

  // Function to validate BEP-20 wallet address
  const isValidWalletAddress = (address) => {
    const regex = /^0x[a-fA-F0-9]{40}$/;
    return regex.test(address);
  };

  const handleConnectWallet = async (e) => {
    e.preventDefault();

    // Validate wallet address before proceeding
    if (!isValidWalletAddress(walletAddress)) {
      showCustomAlert('Invalid wallet address. Please enter a valid BEP-20 address.');
      return;
    }

    setLoading(true);  // Set loading to true when the request starts

    // Assuming you have the user's username stored in localStorage
    const username = localStorage.getItem('username');

    try {
      const response = await fetch(`https://softcoin-axlm.onrender.com/api/connect-wallet`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, walletAddress }),  // Use username instead of userId
      });

      const data = await response.json();
      if (data.success) {
        showCustomAlert('Wallet address connected successfully!');
        // Navigate to the /home route after successful connection
        setTimeout(() => {
          navigate('/home');
        }, 2000);  // Wait 2 seconds before redirecting to show the alert
      } else {
        showCustomAlert('Failed to connect wallet address.');
      }
    } catch (error) {
      console.error('Error connecting wallet:', error);
      showCustomAlert('An error occurred. Please try again.');
    } finally {
      setLoading(false);  // Set loading to false when the request finishes
    }
  };

  const showCustomAlert = (message) => {
    setAlertMessage(message); // Set the custom alert message to trigger the alert
  };

  return (
    <div className="connect-page app">
      <a href="/home"><i className="fas fa-chevron-left" style={{ color: 'white', position: 'fixed', left: '20px', top: '40px', zIndex: '500'}}></i></a>
      <img src="softies/deposit.png" />
      <h3>CONNECT YOUR<br/>BEP-20 WALLET</h3>
      <p className="para" >You need to connect your crypto wallet (BEP-20) for easy withdrawal of your Softie earnings, and to receive your airdrop for TGE.</p>
      <input
        type="text"
        id="wallet-address"
        placeholder="Paste Your Wallet Address Here"
        value={walletAddress}
        onChange={(e) => setWalletAddress(e.target.value)}
        required
      />
      <button
        style={{ width: '50%' }}
        className="submit-button"
        type="submit"
        onClick={handleConnectWallet}
        disabled={loading}  // Disable the button while loading
      >
        {loading ? 'Connecting...' : 'Connect'}  {/* Change button text based on loading state */}
      </button>
      {/* Custom Alert */}
      {alertMessage && <CustomAlert message={alertMessage} onClose={closeAlert} />}
    </div>
  );
}
